<template>
    <div>资讯</div>
</template>

<script>
export default {
    name: 'Journalism',
    data () {
        return {}
    }
}
</script>

<style scoped>

</style>
